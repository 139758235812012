import React from 'react';

import './resource-list.scss';

export interface ResourceEntry {
  // eslint-disable-next-line react/no-unused-prop-types
  title: string;
  // eslint-disable-next-line react/no-unused-prop-types
  subtitle: string;
  // eslint-disable-next-line react/no-unused-prop-types
  url: string;
  // eslint-disable-next-line react/no-unused-prop-types
  description: string;
}

const ResourceList = (
  { resources, getText }: { resources: ResourceEntry[], getText: TextFunction.textContentFunction },
) => {
  const langCode = getText('Language code', { stringOnly: true });
  let processedResources = resources;
  // If not English, remove last word from title
  if (langCode !== 'en') {
    processedResources = resources.map((resource) => {
      const titleWords = resource.title.split(' ');
      titleWords.pop();
      return {
        ...resource,
        title: titleWords.join(' '),
      };
    });
  }
  return (

    <div className="resource-list">
      <h2>{getText('Menu Resources', { stringOnly: true })}</h2>
      {processedResources.map(({
        title, subtitle, description, url,
      }: ResourceEntry) => (
        <div className="resource-list-entry" key={title}>
          { /* Use title without last word */ }
          <h3 className="resource-list-entry__title">{title}</h3>
          <h5 className="resource-list-entry__subtitle">{subtitle}</h5>
          {description}
          <div className="resource-list-entry__open">
            <a href={url} target="_blank" rel="noopener noreferrer">
              {getText('Open', { stringOnly: true })}
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ResourceList;
