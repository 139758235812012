import React from 'react';

import { graphql } from 'gatsby';
import Footer from '../components/footer';
import HeroSection from '../components/hero-section';
import Layout from '../components/layout';
import Menu from '../components/menu';
import ResourceList, { ResourceEntry } from '../components/resource-list';
import SEO from '../components/seo';
import getTextFunctionFromData from '../utils/text';

interface ResourcesProps {
  data: {
    django: {
      readonly contentsForLanguage: TextFunction.titleBodyPair[]
      readonly resourcesForLanguage: ResourceEntry[]
    }
  }
}

const ResourcesPage = (
  { data: { django: { contentsForLanguage, resourcesForLanguage } } }: ResourcesProps,
) => {
  const getText = getTextFunctionFromData(contentsForLanguage);
  return (
    <Layout>
      <SEO
        title={getText('SEO-title', { stringOnly: true })}
        description={getText('resources-seo-description', { stringOnly: true })}
      />
      <Menu goHome getText={getText} />
      <HeroSection getText={getText} />
      <ResourceList resources={resourcesForLanguage} getText={getText} />
      <Footer getText={getText} />
    </Layout>
  );
};

export default ResourcesPage;

export const pageQuery = graphql`
query TranslatedResourceContent($language: Int!) {
  django {
    contentsForLanguage(lang: $language) {
      title
      body
    }
    resourcesForLanguage(lang: $language) {
      description
      subtitle
      title
      url
    }
  }
}
`;
